import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Typography,
  Box,
  Container,
  TextField,
  CssBaseline,
  Button,
  Avatar,
  Select,
  MenuItem,
  IconButton,
  CircularProgress,
  Paper,
  FormControl,
  Tabs,
  Tab
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { connect } from "react-redux";
import {
  Navigate,
  NavLink,
  Route,
  Routes,
  useNavigate,
  useParams
} from "react-router-dom";
import {
  centered_flex_box,
  MainInput,
  MainInputLabel,
  MainPassword,
  main_button,
  StyledInput,
  MainPhone,
  sec_button
} from "../../app/components/Styles";
import { isMobile } from "react-device-detect";
import { Image, Table } from "antd";
import Logo from "../../app/components/logo.png";
import PropTypes from "prop-types";
import MuiPhoneNumber from "material-ui-phone-number";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import ImageComponent from "../../app/components/ImageComponent";
import { getEmployees, getStocks, getWarehouses, deleteStocks, editStocks, getOrders, getProducts, downloadStockQRS } from "../../app/store/actions/imsAdminActions";
import { useEffect } from "react";
import { AddCircleOutline, Delete, DownloadOutlined, Edit } from "@mui/icons-material";
import ActionDialog from "../../app/components/ActionDialog";
import EditModal from "../../app/components/EditModal";
import moment from "moment";
import OrdersList from "./OrdersList";
import ProductsList from "./ProductsList";

export const Warehouse = ({
  getWarehouses,
  getStocks,
  getOrders,
  user,
  warehouse,
  stocks,
  orders,
  deleteStocks,
  editStocks,
  downloadStockQRS,
  isLoading
}) => {
  const navigate = useNavigate();
  const { warehouseId } = useParams();
  const [action, setAction] = React.useState(false);
  const [actionDialog, setActionDialog] = React.useState({
    open: false,
    stock: null,
    content: null
  });
  const [editDialog, setEditDialog] = React.useState({
    open: false,
    item: null,
    title: null,
  });
  const currentLocale = Cookies.get("i18next") || "en";
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getWarehouses({ warehouseId })
    getProducts({ page: 1, limit: 100, warehouseId })
    getOrders({ page: 1, limit: 100, warehouseId })
  }, [])

  const handleDownloadQRs = () => {
    downloadStockQRS({ warehouseId })
  }

  return (
    <div>
      <Box sx={{ width: "100%", padding: 5 }}>
        <Box sx={{ ...centered_flex_box, mb: 1 }}>
          <Typography variant='h4' sx={{ color: "var(--secColor)" }}>Products in {warehouse?.name}</Typography>
          <IconButton onClick={handleDownloadQRs} sx={{ color: "var(--secColor)", mx: 2 }}><DownloadOutlined /></IconButton>
        </Box>
      </Box>
      <ProductsList warehouseId={warehouseId} />
      <hr />
      <OrdersList warehouseId={warehouseId} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  isLoading: state?.records?.isLoading || state?.wait?.isLoading,
  token: state?.auth?.token,
  warehouse: state?.records?.warehouse,
  stocks: state?.records?.stocks,
  orders: state?.records?.orders
});

const mapDispatchToProps = { getWarehouses, getStocks, getOrders, deleteStocks, editStocks, downloadStockQRS };

export default connect(mapStateToProps, mapDispatchToProps)(Warehouse);
