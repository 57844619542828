import {
  FETCH_RECORDS,
  FETCH_RECORDS_SUCCESS,
  FETCH_RECORDS_FAIL,
  WAIT,
  WAIT_SUCCESS,
  WAIT_FAIL
} from "./types";
import {
  delRequest,
  getRequest,
  postRequest,
  putRequest
} from "../../../core/network";
import endpoints from "../../../constants/endPoints.json";
import { notification } from "antd";
import download from "downloadjs";

export const getStore = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  getRequest(data, undefined, token, endpoints.imsAdmin.store)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const getProducts = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  getRequest(data, undefined, token, endpoints.imsAdmin.products)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const downloadInboundsQRS = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: WAIT });
  const token = useState().auth?.token;

  postRequest(data, {responseType: "arraybuffer"} , undefined, token, endpoints.imsAdmin.inbounds.print)
    .then((response) => {
      download(response.data, response.headers["filename"], "application/pdf")
      return dispatch({
        type: WAIT_SUCCESS
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: WAIT_FAIL
      });
    });
};

export const downloadStockQRS = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: WAIT });
  const token = useState().auth?.token;

  getRequest({ ...data, responseType: "arraybuffer" }, undefined, token, endpoints.imsAdmin.stocksDownload)
    .then((response) => {
      download(response.data, response.headers["filename"], "application/pdf")
      return dispatch({
        type: WAIT_SUCCESS
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: WAIT_FAIL
      });
    });
};

export const downloadProductsQRS = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: WAIT });
  const token = useState().auth?.token;

  postRequest(data, {responseType: "arraybuffer"}, undefined, token, endpoints.imsAdmin.productsDownload)
    .then((response) => {
      download(response.data, response.headers["filename"], "application/pdf")
      return dispatch({
        type: WAIT_SUCCESS
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: WAIT_FAIL
      });
    });
};

export const addProducts = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  postRequest(data, undefined, undefined, token, endpoints.imsAdmin.products)
    .then((response) => {
      const { data } = response;
      dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
      navigate && navigate("/ims/admin/products");
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const editProducts = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  putRequest(data, undefined, undefined, token, endpoints.imsAdmin.products)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const deleteProducts = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  delRequest(data, undefined, token, endpoints.imsAdmin.products)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const addInbound = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: WAIT });
  const token = useState().auth?.token;

  postRequest(data, undefined, undefined, token, endpoints.imsAdmin.inbounds.root)
    .then((response) => {
      const { data } = response;
      notification.success({
        message: data?.message,
        style: { marginTop: "10vh" }
      });
      dispatch({
        type: WAIT_SUCCESS
      });
      navigate && navigate("/ims/admin");
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: WAIT_FAIL
      });
    });
};

export const receiveInbound = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: WAIT });
  const token = useState().auth?.token;

  putRequest(data, undefined, undefined, token, endpoints.imsAdmin.inbounds.items)
    .then((response) => {
      const { data } = response;
      notification.success({
        message: data?.message,
        style: { marginTop: "10vh" }
      });
      dispatch({
        type: WAIT_SUCCESS
      });
      navigate && navigate("/ims/admin/inbounds")
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: WAIT_FAIL
      });
    });
};

export const getWarehouses = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  getRequest(data, undefined, token, endpoints.imsAdmin.warehouses)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const addWarehouses = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  postRequest(data, undefined, undefined, token, endpoints.imsAdmin.warehouses)
    .then((response) => {
      const { data } = response;
      dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
      navigate && navigate("/ims/admin/warehouses");
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const editWarehouses = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  putRequest(data, undefined, undefined, token, endpoints.imsAdmin.warehouses)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const deleteWarehouses = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  delRequest(data, undefined, token, endpoints.imsAdmin.warehouses)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const getLocations = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  getRequest(data, undefined, token, endpoints.imsAdmin.locations)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const addLocations = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  postRequest(data, undefined, undefined, token, endpoints.imsAdmin.locations)
    .then((response) => {
      const { data } = response;
      dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
      navigate && navigate("/ims/admin/warehouses");
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const editLocations = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  putRequest(data, undefined, undefined, token, endpoints.imsAdmin.locations)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const deleteLocations = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  delRequest(data, undefined, token, endpoints.imsAdmin.locations)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const getStocks = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  getRequest(data, undefined, token, endpoints.imsAdmin.stocks)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const addStocks = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  postRequest(data, undefined, undefined, token, endpoints.imsAdmin.stocks)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const editStocks = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  putRequest(data, undefined, undefined, token, endpoints.imsAdmin.stocks)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const deleteStocks = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  delRequest(data, undefined, token, endpoints.imsAdmin.stocks)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const getOrders = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  getRequest(data, undefined, token, endpoints.imsAdmin.orders)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const addOrders = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  postRequest(data, undefined, undefined, token, endpoints.imsAdmin.orders)
    .then((response) => {
      const { data } = response;
      dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
      navigate && navigate("/ims/admin/orders");
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const editOrders = (data, setAction, action) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  putRequest(data, undefined, undefined, token, endpoints.imsAdmin.orders)
    .then((response) => {
      const { data } = response;
      setAction && setAction(!action)
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const deleteOrders = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  delRequest(data, undefined, token, endpoints.imsAdmin.orders)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const getEmployees = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  getRequest(data, undefined, token, endpoints.imsAdmin.employees)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const addEmployees = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  postRequest(data, undefined, undefined, token, endpoints.imsAdmin.employees)
    .then((response) => {
      const { data } = response;
      dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
      navigate && navigate("/ims/admin/employees");
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const editEmployees = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  putRequest(data, undefined, undefined, token, endpoints.imsAdmin.employees)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const deleteEmployees = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  delRequest(data, undefined, token, endpoints.imsAdmin.employees)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const getVendors = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  getRequest(data, undefined, token, endpoints.imsAdmin.vendors)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const addVendors = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  postRequest(data, undefined, undefined, token, endpoints.imsAdmin.vendors)
    .then((response) => {
      const { data } = response;
      dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
      navigate && navigate("/ims/admin/brands");
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const editVendors = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  putRequest(data, undefined, undefined, token, endpoints.imsAdmin.vendors)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const deleteVendors = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  delRequest(data, undefined, token, endpoints.imsAdmin.vendors)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const getPartners = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  getRequest(data, undefined, token, endpoints.imsAdmin.partners)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const addPartners = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  postRequest(data, undefined, undefined, token, endpoints.imsAdmin.partners)
    .then((response) => {
      const { data } = response;
      dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
      navigate && navigate("/ims/admin/brands");
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const editPartners = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  putRequest(data, undefined, undefined, token, endpoints.imsAdmin.partners)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const deletePartners = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  delRequest(data, undefined, token, endpoints.imsAdmin.partners)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message,
        style: { marginTop: "10vh" }
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};