import * as React from "react";
import {
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { connect } from "react-redux";
import {
  Navigate,
  useNavigate,
} from "react-router-dom";
import { Button, Image, Space, Table, Tag } from "antd";
import moment from "moment";
import ImageComponent from "./ImageComponent";
import { MainInput } from "./Styles";
import { receiveInbound } from "../store/actions/imsAdminActions";

export const InboundsTable = ({ products, receiveInbound, rowSelection, isLoading }) => {
  const navigate = useNavigate();
  const [quantities, setQuantities] = React.useState({})

  React.useEffect(() => {
    if(products&& !products.docs) {
      const intermediate = {}
      products?.map((product) => intermediate[product._id] = 0)
      setQuantities(intermediate)
    }
  }, [products])

  const columns = [
    {
      title: 'Inbound ID',
      dataIndex: 'inboundId',
    },
    {
      title: 'Product SKU',
      dataIndex: 'sku',
      filters: [
        ...new Map(
          products
            ?.filter((product) => product.product !== null)
            .map((product) => [
              product.product.sku, // Key to ensure uniqueness
              { text: product.product.sku, value: product.product.sku },
            ])
        ).values(),
      ],
      filterSearch: true,
      onFilter: (value, record) => record.sku.startsWith(value),
    },
    {
      title: 'Date Created',
      dataIndex: 'date',
      sorter: (a, b) => {return moment(a.date).isBefore(b.date) ? -1 : 1},
    },
    {
      title: 'Image',
      dataIndex: 'image',
      render: (text) => <ImageComponent width={50} height={50} src={text} preview={true} />,
    },
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Size',
      dataIndex: 'size',
      filters: [
        ...new Map(
          products
            ?.filter((product) => {return product.product.size}).map((product) => [
              product.product.size, // Key to ensure uniqueness
              { text: product.product.size, value: product.product.size },
            ])
        ).values(),
      ],
      filterSearch: true,
      onFilter: (value, record) => record.size?.startsWith(value),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: [
        {
          text: "Pending",
          value: "Pending"
        },
        {
          text: "Receiving",
          value: "Receiving"
        },
        {
          text: "Partially Received",
          value: "Partially Received"
        },
        {
          text: "Received",
          value: "Received"
        },
        {
          text: "Cancelled",
          value: "Cancelled"
        }
      ],
      onFilter: (value, record) => record.status === value,
      render: (_, {status}) => <Tag color={status === "Received" ? "green" : status === "Cancelled" ? "red" : status === "Pending" ? "yellow" : "blue"}>{status.toUpperCase()}</Tag>
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      filters: [
        ...new Map(
          products
            ?.filter((product) => product.inbound.vendor !== null)
            .map((product) => [
              product.inbound.vendor.name, // Key to ensure uniqueness
              { text: product.inbound.vendor.name, value: product.inbound.vendor.name },
            ])
        ).values(),
      ],
      filterSearch: true,
      onFilter: (value, record) => record.vendor.startsWith(value),
    },
    {
      title: 'QTY Sent',
      dataIndex: 'quantity',
    },
    {
      title: 'QTY Received',
      dataIndex: 'quantityReceived',
    },
    // {
    //   title: 'Receive Quantity',
    //   render: (_, {_id, quantity, quantityReceived}) => <Space>
    //     <MainInput
    //       margin="normal"
    //       type="number"
    //       fullWidth
    //       label="QTY"
    //       id={_id}
    //       inputProps={{
    //         min: 0,
    //         max: quantity - quantityReceived
    //       }}
    //       value={quantities[_id]}
    //       onChange={({target}) => {setQuantities({...quantities, [_id]: parseInt(target.value)})}}
    //     />
    //   </Space>
    // },
    // {
    //   title: 'Actions',
    //   render: (_, {_id, inboundId}) => <Space>
    //     <Button onClick={() => receiveInbound({ 
    //       inboundId,
    //       itemUpdates: [
    //         {
    //           _id,
    //           quantityReceived: quantities[_id]
    //         }
    //       ]
    //     })}> Receive</Button>
    //   </Space>
    // },
  ];

  const data = products?.map((row) => {
    return {
      _id: row._id,
      key: row._id,
      inboundId: row.inbound._id,
      date: moment(row.createdAt).format("lll"),
      sku: row.product.sku,
      image: row.product.image,
      name: row.product.name,
      size: row.product.size,
      color: row.product.color,
      weight: row.product.weight,
      status: row.inbound.status,
      quantity: row.quantity,
      quantityReceived: row.quantityReceived,
      vendor: row.inbound.vendor.name,
    }
  })

  return (
    <div>
      <Box sx={{ width: "100%", padding: 5 }}>
        {quantities && <Table
          columns={columns}
          rowSelection={rowSelection}
          dataSource={data}
          loading={isLoading}
          showSorterTooltip={{
            target: 'sorter-icon',
          }}
        />}
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state?.records?.isLoading
});

const mapDispatchToProps = { receiveInbound };

export default connect(mapStateToProps, mapDispatchToProps)(InboundsTable);
