import * as React from 'react';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, FormHelperText, InputLabel, CircularProgress, FormControl, Tabs, Tab, Card, CardHeader, CardMedia, IconButton, Autocomplete } from '@mui/material';
import { connect } from "react-redux";
import { Navigate, NavLink, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { centered_flex_box, MainInput, MainInputLabel, MainPassword, main_button, StyledInput, MainPhone, sec_button, left_flex_box } from '../../app/components/Styles';
import { isMobile } from 'react-device-detect';
import { getVendors, addInbound, getProducts } from '../../app/store/actions/imsAdminActions.js';
import { Close, Delete, Download, Upload } from '@mui/icons-material';
import { useEffect } from 'react';

export const CreateOutbound = ({ addInbound, getVendors, getProducts, products, vendors, isLoading }) => {

    const navigate = useNavigate();
    const [stockItems, setStockItems] = React.useState([]);
    const [vendorId, setVendorId] = React.useState(null);
    const [notes, setNotes] = React.useState(null);


    const handleSubmit = async (event) => {
        event.preventDefault();
        var data = {
            products: stockItems,
            vendorId,
            notes
        }
        addInbound(data, navigate);
    };

    const addItem = (value) => {
        if(!value) {
            return
        }
        setStockItems(prevItems => {
            let found = prevItems.find(item => item.stockItem === value._id);
            if (!found) {
                return [
                    ...prevItems,
                    {
                        _id: value._id,
                        name: value.name,
                        quantity: 1,
                        stock: value.stock
                    }
                ];
            }
            return prevItems;
        });
    };

    const editItem = (idx, { target }) => {
        setStockItems(prevItems => {
            let newItems = [...prevItems];
            newItems[idx].quantity = target.value;
            return newItems;
        });
    };

    const removeItem = (id) => {
        let currItems = stockItems.filter((item) => item._id !== id)
        setStockItems(currItems)
    }

    useEffect(() => {
        getVendors({ page: 1, limit: 500 })
    }, [])

    useEffect(() => {
        if(vendorId) {
            getProducts({ page: 1, limit: 10000, vendorId, inStock: true })
        }
    }, [vendorId])

    return (
        <Container maxWidth="xs">
            <Box
                sx={{
                    paddingTop: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Box sx={{ width: '100%', mt: 2 }}>
                    <Typography mt={2} variant='h4' textAlign={"center"} sx={{ color: "var(--secColor)" }}>Create Outbound</Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        {vendors && <Autocomplete
                            options={vendors}
                            onChange={(_, value) => setVendorId(value ? value._id : null)}
                            getOptionLabel={(vendor) => { return vendor.brandName }}
                            renderInput={(params) => <MainInput
                                {...params}
                                margin="normal"
                                fullWidth
                                label="Select Brand"
                            />}
                        />}
                        {vendorId && products && <Autocomplete
                            options={products}
                            onChange={(_, value) => addItem(value)}
                            getOptionLabel={(product) => { return `(${product.sku}) ${product.name} [${product.stock.amount} Pcs.]`}}
                            renderInput={(params) => <MainInput
                                {...params}
                                margin="normal"
                                fullWidth
                                label="Add Products"
                            />}
                        />}
                        <hr />
                        <Grid container spacing={2}>
                            {stockItems.map((product, idx) => {
                                return (
                                    <>
                                        <Grid item xs={8} sx={{ ...left_flex_box }}>
                                            <Typography>{product.name} {product.size && `(${product.size})`} {product.color && `(${product.color})`} {product.weight && `(${product.weight})`}</Typography>
                                            {/* <Typography>{product.externalId}</Typography> */}
                                        </Grid>
                                        <Grid item xs={3}>
                                            <MainInput
                                                margin="normal"
                                                required
                                                fullWidth
                                                label="Quantity"
                                                type="number"
                                                inputProps={{ min: 1, max: product.stock.amount }}
                                                value={stockItems[idx].quantity}
                                                onChange={(event) => editItem(idx, event)}
                                                autoFocus
                                            />
                                        </Grid>
                                        <Grid item xs={1} sx={{ ...centered_flex_box }}>
                                            <IconButton onClick={() => removeItem(product._id)}><Delete /></IconButton>
                                        </Grid>
                                    </>
                                )
                            })}
                            <Grid item xs={12}>
                                <MainInput
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Notes"
                                    multiline
                                    minRows={5}
                                    value={notes}
                                    onChange={({target}) => setNotes(target.value)}
                                    autoFocus
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, ...main_button }}
                        >
                            {isLoading ? <CircularProgress sx={{ color: "white" }}/> : "Send Inventory Outside Chainz"}
                        </Button>
                    </Box>
                </Box>
            </Box >
        </Container >
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.records?.isLoading || state?.wait?.isLoading,
    vendors: state?.records?.vendors?.docs,
    products: state?.records?.products?.docs
});

const mapDispatchToProps = { getVendors, addInbound, getProducts };

export default connect(mapStateToProps, mapDispatchToProps)(CreateOutbound);