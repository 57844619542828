import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Typography,
  Box,
  Container,
  TextField,
  CssBaseline,
  Button,
  Avatar,
  Select,
  MenuItem,
  IconButton,
  TableContainer,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  FormControl,
  Tabs,
  Tab,
  Card,
  CardContent,
  CardHeader,
  Divider
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { connect } from "react-redux";
import { loginIMSAdmin, signUp } from "../../app/store/actions/authActions";
import {
  Navigate,
  NavLink,
  Route,
  Routes,
  useNavigate,
  useParams
} from "react-router-dom";
import {
  centered_flex_box,
  MainInput,
  MainInputLabel,
  MainPassword,
  main_button,
  StyledInput,
  MainPhone,
  sec_button
} from "../../app/components/Styles";
import { isMobile } from "react-device-detect";
import { Image } from "antd";
import Logo from "../../app/components/logo.png";
import PropTypes from "prop-types";
import MuiPhoneNumber from "material-ui-phone-number";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import ImageComponent from "../../app/components/ImageComponent";
import { getOrders } from "../../app/store/actions/imsAdminActions";
import { useEffect } from "react";
import { AddCircleOutline, Download } from "@mui/icons-material";
import moment from "moment";
import download from "downloadjs";
import axios from "axios";

export const Order = ({ getOrders, user, order, isLoading }) => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [ gettingAWB, setGettingAWB ] = React.useState(false);

  useEffect(() => {
    getOrders({ orderId })
  }, [])

  const handlePrintAWBs = () => {
    setGettingAWB(true)
    axios.get(`${process.env.REACT_APP_SHIPBLU_ENDPOINT}/v1/orders/shipping-label/`, {
      params: {
        "type": "pdf",
        "tracking_numbers": order?.shipbluTrackingId
      },
      headers: {
        Authorization: process.env.REACT_APP_SHIPBLU_KEY
      },
      responseType: "arraybuffer"
    }).then(({ data }) => {
      download(data, `Order ${order?.shipbluTrackingId} AWBs.pdf`, 'application/pdf')
    }).finally(() => {
      setGettingAWB(false);
    })
  }

  return (
    <div>
      <Box sx={{ width: "100%", padding: 5 }}>
        <Box sx={{ ...centered_flex_box, mb: 1 }}>
          <Typography variant='h4' sx={{ color: "var(--secColor)" }}>Order for "{order?.vendor.name}"</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Card>
              <CardContent>
                <Typography>Order {orderId}</Typography>
                <Typography>Created at {moment(order?.createdAt).format("lll")}</Typography>
                <Typography fontWeight="bold">Status: {order?.status}</Typography>
                {order?.assignedAt && <Typography>Assigned to employee at {moment(order?.assignedAt).format("lll")}</Typography>}
                {order?.preparedAt && <Typography>Packed at {moment(order?.preparedAt).format("lll")}</Typography>}
                {order?.delivered && <Typography>Delivered at {moment(order?.delivered).format("lll")}</Typography>}
                {order?.assignee && <Typography>Employee: {order?.assignee.name}</Typography>}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={8}>
            <Card>
              <CardContent>
                <Typography variant="h5" textAlign="center">Shipping Details <IconButton onClick={() => handlePrintAWBs()} sx={{ mx: 2 }}><Download /></IconButton></Typography>
                <Divider />
                {order?.orderFulfillment?.shippingAddress?.name && <Typography>Name: {order?.orderFulfillment?.shippingAddress?.name}</Typography>}
                {order?.orderFulfillment?.shippingAddress?.phone && <Typography>Phone Number: <NavLink to={`tel:"${order?.orderFulfillment?.shippingAddress?.phone}`}>{order?.orderFulfillment?.shippingAddress?.phone}</NavLink></Typography>}
                {order?.orderFulfillment?.shippingAddress?.address1 && <Typography>Address 1: {order?.orderFulfillment?.shippingAddress?.address1}</Typography>}
                {order?.orderFulfillment?.shippingAddress?.address2 && <Typography>Address 2: {order?.orderFulfillment?.shippingAddress?.address2}</Typography>}
                {order?.orderFulfillment?.price && <Typography fontWeight={"bold"}>Total Price: {order?.orderFulfillment?.price} EGP</Typography>}
              </CardContent>
            </Card>
            <Card sx={{mt: 2}}>
              <CardContent>
                <Typography variant="h5" textAlign="center">Items</Typography>
                <Divider />
                <Box sx={{ p: 2 }}>
                    {order?.orderItems && order?.orderItems.length > 0 && order?.orderItems.map((item) => {
                        return (
                            <Card key={item._id} sx={{ width: "100%", mb: 1 }}>
                                <CardContent sx={{ ...centered_flex_box, flexDirection: "column", width: "100%" }}>
                                    <Avatar src={item.stockItem.product.image} />
                                    <Typography fontWeight={"bold"} textAlign={"center"}>{item.stockItem.product.name}</Typography>
                                    <Typography>{item.stockItem.product.externalId}</Typography>
                                    <hr/>
                                    {item.stockItem.product.size && <Typography>Size: {item.stockItem.product.size}</Typography>}
                                    {item.stockItem.product.weight && <Typography>Weight: {item.stockItem.product.weight}</Typography>}
                                    {item.stockItem.product.color && <Typography>Color: {item.stockItem.product.color}</Typography>}
                                    <hr/>
                                    <Typography fontWeight={"bold"}>Quantity: {item.quantityOrdered}</Typography>
                                </CardContent>
                            </Card>
                        )
                    })}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  isLoading: state?.records?.isLoading || state?.wait?.isLoading,
  token: state?.auth?.token,
  order: state?.records?.order
});

const mapDispatchToProps = { getOrders };

export default connect(mapStateToProps, mapDispatchToProps)(Order);
